import React from "react";
import {graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

import InternalLayout from "../layouts/internallayout";
import styled from "styled-components";
import Seo from "../components/seo";

const GreyWrapper = styled.div`
    margin: 2em 0;
    background: #f4f4f4;
  
    .container{
      display: flex;
      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
      }
    }
`

const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 4em 1em;
    position: relative;
    overflow: hidden;
`

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
  
  :after{
  clear: both;
  width: 100%;
  }
`

const TeamBio = styled.div`
  width: 100%;
  padding: 0;
  height: fit-content;
  h2{
    margin-top: 0;
  }
  
  @media (min-width: 768px) {
    width: calc(66% - 2rem);
    padding-right: 2rem;
    margin: auto;
  }
`

const EquipmentPhoto = styled(GatsbyImage)`
    display: block;
    width: 100%;    
    height: 200px;
    
        @media (min-width: 768px) {
            //width: calc(33% - 2rem);
            height: auto;
            max-height: 400px;
            margin-top: 6rem;
          margin-right: 2rem;
        }
`
const TeamPhoto = styled(GatsbyImage)`
    display: block;
    width: 100%;    
    height: 200px;
    
        @media (min-width: 768px) {
            width: calc(33% - 2rem);
            height: auto;
          max-height: 400px;
            margin-top: 6rem;
        }
`

class AboutUsPage extends React.Component {

    render() {
        return (
    <InternalLayout>
        <Seo title="About how Element Physiotherapy can help you"/>

        <Container>
            <h1>About</h1>

            <h2>How can Element Physiotherapy help you?</h2>


            <p>You can be assured that as qualified and experienced physiotherapists and exercise physiologists we are able to treat a wide range of problems.</p>

            <p>Whether you have experienced a sporting or workplace inquiry, require pre or post operative care, seek treatment after a motor vehicle accident, need exercise rehabilitation, geriatric care, fracture treatment or pain management services, we can help.</p>

            <p>As a patient of Element Physio you can expect:</p>

            <ul>
                <li>A plain-speak diagnosis of your situation to make sure we don’t merely treat your symptoms</li>
                <li>A comprehensive treatment plan</li>
                <li>Timely and confidential communication with any other healthcare professionals involved in your treatment</li>
                <li>Private consultation rooms and an onsite exercise rehabilitation centre</li>
               <li>Affordable consultation fees with direct billing to medicare and /or your health insurance provider</li>
            </ul>
        </Container>
        <GreyWrapper>
            <Container className="container">
                <EquipmentPhoto
                    image={this.props.data.physioequipment.childImageSharp.gatsbyImageData}
                    alt="We have a wide range of Physiotherapy Equipment"
                />
                <div>
                    <h2>What’s unique about Element Physiotherapy?</h2>

                    <p>
                        You will experience a thorough assessment and diagnosis that uncovers the underlying cause of your condition
                        prior to any treatment occurring. We don’t rush in with a quick diagnosis and hope for the best. Instead, <strong>we
                        take our time to understand your problem</strong> before recommending the right treatment plan.
                    </p>
                    <p>
                        Element Physiotherapists are focused on excellence in patient care. This means <strong>we provide clear diagnosis so
                        you aren’t left wondering</strong> why you’re in pain or what’s required to improve your movement.
                    </p>
                    <p>
                        It also means that we take a holistic approach to your health by having your <strong>overall well-being in mind</strong> when planning and delivering treatment.
                    </p>
                    <p>
                        Lastly, excellence in patient care means that our <strong>appointments typically run longer</strong> as we take our time to really understand your particular situation before recommending a comprehensive treatment program.
                    </p>
                    <p>
                        <strong>We treat each patient with dignity and respect</strong>, not as a number to quickly process.
                    </p>
                </div>
            </Container>
        </GreyWrapper>
        <Container>
            <h2>Meet the Element Physiotherapy Team</h2>
            <p>
                You want qualified and experienced physiotherapists treating you - that’s exactly who work at Element
                Physio.
            </p>
            <p>
                Our physiotherapists have postgraduate university training, excellent clinical skills, and understand that
                clear communication is paramount.
            </p>
            <p>
                We seek to provide you with:
            </p>
            <ol>
                <li>The right diagnosis</li>
                <li>Best practice physiotherapy treatment</li>
                <li>Education and self-management plans (meaning fewer visits with us and a pathway to prevent future recurrence of your condition)</li>
            </ol>
            <br />
            <TeamMember>
                <TeamBio>
                    <h2>Jason Mcminimee</h2>
                    <p><strong>APA Physiotherapist & Exercise Physiologist</strong></p>
                    <p>Jason is a registered Physiotherapist who graduated from Sydney University over 10 years ago and is a current member of the Australian Physiotherapy Association. He completed his undergraduate study at ACU in 2006 with registration as an Exercise Physiologist with Exercise Sports Science Australia and Sports Medicine Australia.</p>

                    <p>Jason has experience in public healthcare also in Sydney and Central Coast Hospitals completing rotations in various area's as a Physiotherapist.</p>

                    <p>Jason has been in private practice for the last 12 years working in a large physiotherapy practice alongside Orthopods from North Sydney Orthopaedics treating a range of musculoskeletal, orthopaedic, neurological and sporting conditions.</p>

                    <p>He has been on the medical staff for the Central Coast Mariners for seasons 2011-2013 and has grown up enjoying semi-professional football in Sydney and on the Coast also relishing a season in Scotland with league 1 side Arbroath.</p>

                    <p>Jason partakes in regular continuing education each year with a view to further specialisation as an APA Physiotherapist.</p>

                    <p>Jason is married to Kate. They are members at EV Church and have three sons.</p>
                </TeamBio>
                <TeamPhoto
                    // style={serviceImage}
                    image={this.props.data.jason.childImageSharp.gatsbyImageData}
                    alt="Jason Mcminimee"
                    objectPosition="50% 50%"
                />
            </TeamMember>

            {/*<TeamMember>*/}
            {/*    <TeamBio>*/}
            {/*        <h2>Stuart Adams</h2>*/}
            {/*        <p><strong>APA Physiotherapist</strong></p>*/}
            {/*        <p>Stuart is the newest member to Element Physiotherapy after graduating from Sydney University with a Masters of Physiotherapy in 2019. With an undergraduate degree in Human science majoring in Human movement at Macquarie University, Stuart undertook further study in Exercise Sports Science Australia at CSU where he received an Honourable Dean’s award for his academic achievements.</p>*/}

            {/*        <p>Stuart has developed his skills as a Physiotherapist after completing placements at Royal North Shore and Prince of Wales Hospitals, spending time in acute Cardio/respiratory, Musculoskeletal outpatients, and Neurological rehabilitation.</p>*/}

            {/*        <p>Stuart is a current member of the Australian Physiotherapy Association and is committed to ongoing education each year to ensure evidence-based practice and clinical development.</p>*/}

            {/*        <p>Stuart has grown up playing with the Mariners academy and more recently plays first grade football with Avoca FC where he has been named Central Coast footballer of the year and rubbed shoulders on the pitch with the likes of Usain Bolt.</p>*/}

            {/*        <p>He is Married to Brittany and are both members at EV Church.</p>*/}
            {/*    </TeamBio>*/}
            {/*    <TeamPhoto*/}
            {/*        // style={serviceImage}*/}
            {/*        image={this.props.data.stuart.childImageSharp.gatsbyImageData}*/}
            {/*        alt="Stuart Adams"*/}
            {/*    />*/}
            {/*</TeamMember>*/}

            {/*<TeamMember>*/}
            {/*    <TeamBio>*/}
            {/*        <h2>Michelle Garden</h2>*/}
            {/*        <p><strong>Reception</strong></p>*/}
            {/*    </TeamBio>*/}
            {/*    <TeamPhoto*/}
            {/*        // style={serviceImage}*/}
            {/*        image={this.props.data.michelle.childImageSharp.gatsbyImageData}*/}
            {/*        alt="Michelle Garden"*/}
            {/*    />*/}
            {/*</TeamMember>*/}
        </Container>

    </InternalLayout>
        )
    }
}

export default AboutUsPage


export const pageQuery = graphql`
    query {
        physioequipment: file(relativePath: {eq: "physio-equipment.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 95
              )
            }
          } 
        jason: file(relativePath: {eq: "team/jason.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 90
              )
            }
          } 
          stuart: file(relativePath: {eq: "team/stuart.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 90
              )
            }
          } 
          michelle: file(relativePath: {eq: "team/michelle.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 90
              )
            }
          } 
    }
`;
